import styled from '@emotion/styled';

export const StyledMenuTrigger = styled.button`
  display: block;
  width: 40px;
  padding: 7px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &:focus,
  &:hover {
    .line {
      &:nth-of-type(2) {
        width: 80%;
      }
    }
  }

  .line {
    display: block;
    width: 100%;
    height: 3px;
    margin: 0 0 4px;
    background-color: #222222;
    transition: width .3s;

    &:last-of-type {
      margin: 0;
    }
  }
`;
