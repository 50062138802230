import styled from '@emotion/styled';

export const StyledNavigation = styled.nav`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1010;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  padding: 60px 0 30px;
  background-color: #F5F5F5;
  box-shadow: ${({ open }) => open ? '0 0 200px rgba(0, 0, 0, .15)' : 'none'};
  transform: translateX(${({ open }) => open ? '0' : '100%'});
  overflow: hidden;
  transition: transform .6s ease, box-shadow .6s ease;

  .close-menu {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    &:focus,
    &:hover {
      &::after,
      &::before {
        background-color: #222222;
      }
    }

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 29px;
      left: 15px;
      width: 30px;
      height: 2px;
      background-color: #afafaf;
      transition: background-color .3s;
    }

    &::after {
      transform: rotate(45deg);
      transform-origin: center;
    }

    &::before {
      transform: rotate(-45deg);
      transform-origin: center;
    }
  }

  ul {
    box-sizing: content-box;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    max-height: 100%;
    margin: 0 -20px 0 0;
    padding-right: 20px;
    overflow-y: auto;

    &.menu-is-open {
      li {
        opacity: 1;
        transform: translateX(0);

        &:nth-of-type(1) {
          transition: transform .5s .4s ease, opacity .5s .4s ease;
        }

        &:nth-of-type(2) {
          transition: transform .5s .6s ease, opacity .5s .6s ease;
        }

        &:nth-of-type(3) {
          transition: transform .5s .7s ease, opacity .5s .7s ease;
        }

        &:nth-of-type(4) {
          transition: transform .5s .9s ease, opacity .5s .9s ease;
        }

        &:nth-of-type(5) {
          transition: transform .5s 1.1s ease, opacity .5s 1.1s ease;
        }
      }
    }

    li {
      display: block;
      margin: 0;
      opacity: 0;
      transform: translateX(100px);
      transition: transform 0s .3s, opacity .3s;
    }

    a {
      display: block;
      padding: 10px;
      color: #afafaf;
      letter-spacing: 1px;
      text-align: center;
      text-decoration: none;
      transition: color .3s;

      &.active,
      &:focus,
      &:hover {
        color: inherit;
        outline: none;
      }
    }
  }

  @media (min-width: 576px) {
    ul {
      a {
        font-size: 1.25rem;
      }
    }
  }

  @media (min-width: 768px) {
    width: 50%;
    padding: 60px 80px 30px;

    ul {
      align-items: flex-start;
    }
  }

  @media (min-width: 992px) {
    width: 45%;
    padding: 60px 100px 30px;
  }

  @media (min-width: 1200px) {
    width: 40%;
  }

  @media (min-width: 1440px) {
    width: 34%;
  }

  @media (min-width: 1580px) {
    width: 30%;
  }
`;
