import React from 'react';
import { StyledLayout } from './Layout.styled';

import Header from '../Header';
import Footer from '../Footer';

const Layout = ({ children, isHome }) => {

  const [scroll, setScroll] = React.useState(0);
  const [fixed, setFixed] = React.useState(false);

  React.useEffect(() => {
    const fixedHeader = () => {
      const header = document.querySelector('header');
      const headerHeight = header.offsetHeight;
      const windowScrollTop = window.scrollY;

      windowScrollTop > 5 ? setFixed(true) : setFixed(false);

      if (Math.abs(scroll - windowScrollTop) > 5) {
        if (windowScrollTop > scroll && windowScrollTop >= 5) {
          header.style.top = `-${headerHeight}px`;
        } else {
          header.removeAttribute('style');
        }
        setScroll(windowScrollTop);
      }
    };

    window.addEventListener('scroll', fixedHeader);

    return () => {
      window.removeEventListener('scroll', fixedHeader);
    }
  }, [scroll])

  return(
    <StyledLayout>
      <Header fixed={fixed}/>
      <main>
        {children}
      </main>
      <Footer isHome={isHome}/>
    </StyledLayout>
  );
};

export default Layout;
