import React from 'react';
import { StyledFooter } from './Footer.styled';

const Footer = ({ isHome }) => (
  <StyledFooter isHome={isHome}>
    <div className="container">
      <p className="copyright">
        © <strong>BPD Rafał Matusik</strong> {new Date().getFullYear()}. All rights reserved. <br />
        Created by <a href="https://codemag.pl">Codemag</a>
      </p>
    </div>
  </StyledFooter>
);

export default Footer;
