import styled from '@emotion/styled';

export const StyledHeader = styled.header`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 0;
  background-color: ${({ fixed }) => fixed ? '#e0e2e5' : 'transparent'};
  box-shadow: ${({ fixed }) => fixed ? '0 0 20px rgba(0, 0, 0, .2)' : 'none'};
  transition: top .5s, background-color .3s, box-shadow .3s, padding .3s;

  @media (min-width: 1580px) {
    padding: ${({ fixed }) => fixed ? '15px 0' : '22.5px 0'};
  }

  .container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    display: block;
    width: auto;
    height: 30px;
    margin: 0;

    @media (min-width: 576px) {
      height: 35px;
    }
  }

  .contact-info {
    display: none;
    color: rgba(34, 34, 34, .6);

    @media (min-width: 576px) {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin: 0 20px 0 auto;
      font-size: .875rem;

      div {
        display: none;
        position: relative;
        margin: 0 30px 0 0;
        padding-left: 25px;

        &:nth-of-type(2) {
          display: block;
        }

        a {
          color: inherit;
          text-decoration: none;
          transition: color .3s;

          &:focus,
          &:hover {
            color: #222222;
          }
        }

        svg {
          display: block;
          position: absolute;
          top: 4px;
          left: 0;
          width: 16px;
          height: 16px;
          fill: #a92d37;
        }
      }
    }

    @media (min-width: 768px) {
      div {
        &:nth-of-type(3) {
          display: block;
        }
      }
    }

    @media (min-width: 992px) {
      div {
        &:nth-of-type(1) {
          display: block;
        }
      }
    }
  }
`;
