import styled from '@emotion/styled';

export const StyledFooter = styled.footer`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;

  .copyright {
    margin: 0;
    font-size: .75rem;
    line-height: 1.5;
    text-align: center;
    color: ${({ isHome }) => isHome ? '#e0e2e5' : 'inherit' };

    strong {
      text-transform: uppercase;
    }

    a {
      color: inherit;
      transition: opacity .3s;
      text-decoration: none;

      &:focus,
      &:hover {
        outline: none;
        opacity: .5;
      }
    }
  }

  @media (min-width: 992px) {
    .copyright {
      font-size: .8125rem;
      text-align: left;
    }
  }

  @media (min-width: 1580px) {
    .copyright {
      color: inherit;
    }
  }
`;
