import React from 'react';
import { Link } from 'gatsby';
import { StyledNavigation } from './Navigation.styled';

const Navigation = ({open, setOpen}) => {

  const closeMenu = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const notCloseMenu = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  React.useEffect(() => {
    const hideMenu = () => {
      setOpen(false);
    }

    document.body.addEventListener('click', hideMenu);

    return () => {
      document.body.removeEventListener('click', hideMenu);
    };
  }, [setOpen]);

  return (
    <StyledNavigation open={open} onClick={notCloseMenu}>
      <button className="close-menu" onClick={closeMenu}></button>

      <ul className={open ? 'menu-is-open': ''}>
        <li>
          <Link to="/" activeClassName="active">Strona główna</Link>
        </li>
        <li>
          <Link to="/o-nas/" activeClassName="active">O nas</Link>
        </li>
        <li>
          <Link to="/wspolpraca/" activeClassName="active">Współpraca</Link>
        </li>
        <li>
          <Link to="/kariera/" activeClassName="active">Kariera</Link>
        </li>
        <li>
          <Link to="/kontakt/" activeClassName="active">Kontakt</Link>
        </li>
      </ul>
    </StyledNavigation>
  )
};

export default Navigation;
