import React from 'react';
import { StyledMenuTrigger } from './MenuTrigger.styled';

const MenuTrigger = ({open, setOpen}) => {

  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <StyledMenuTrigger onClick={toggleMenu} tabIndex="0">
      <span className="line"></span>
      <span className="line"></span>
      <span className="line"></span>
    </StyledMenuTrigger>
  );
};

export default MenuTrigger;
